<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <progress-spinner :isLoading="loading" />
    <v-toolbar-title>{{this.areaDetails?.Name}}</v-toolbar-title>
    <br>
    <v-row>
      <v-col cols="12">
        <v-toolbar-title>Assigned</v-toolbar-title>
        <v-card>
          <v-data-table
            :key="assignedKey"
            v-if="this.employeesAssigned.length > 0"
            :headers="assignedHeaders"
            :items="employeesAssigned"
            item-key="Id"
            class="elevation-1 row-pointer"
          >
            <template v-slot:item.PriorityStatus="{ item }">
              <v-btn
                min-width="100"
                class="primary pa-0"
                @click="handlePromptPriority(item)"
              >
                {{ item.PriorityStatus }}
              </v-btn>
            </template>
            <template v-slot:item.Action="{ item }">
              <div @click="handleRemoveWorker(item)" class="mdi mdi-close-circle text-h3 red--text"></div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-toolbar-title>Assignable</v-toolbar-title>
        <v-card>
          <v-data-table
            :headers="assignableHeaders"
            :items="this.assignable"
            :options.sync="assignableOptions"
            :server-items-length="totalAssignable"
            :loading="loading"
            sort-by="Name"
            item-key="Id"
            class="elevation-1 row-pointer"
          >
            <template v-slot:item.Name="{ item }">
              {{`${item.FirstName} ${item.LastName}`}}
            </template>
            <template v-slot:item.Action="{item}">
              <v-btn class="primary pa-0" @click="handlePromptPriority(item)">Assign</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-3" />

    <v-dialog v-model="showDialog" activator="parent" width="500">
      <v-card>
        <v-card-title>Define priority</v-card-title>
        <v-card-text class="black--text">What priority should
          <span v-if="this.selectedEmployee?.Employee?.FirstName">
            <b>{{`${this.selectedEmployee?.Employee?.FirstName} ${this.selectedEmployee?.Employee?.LastName}`}}</b>
          </span>
          <span v-else>
            <b>{{`${this.selectedEmployee?.FirstName} ${this.selectedEmployee?.LastName}`}}</b>
          </span>
          have in the <b>{{this.areaDetails?.Name ?? ''}}</b> area?</v-card-text>
        <v-card-text class="black--text">
          <v-radio-group v-model="selectedPriority">
            <v-radio v-for="(option, index) in this.priorityOptions" :label="option.Name" :value="option.Id" :key="index"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="blue--text" @click="showDialog = false">
            Cancel
          </v-btn>
          <v-btn text class="blue--text" @click="handleAssignEmployeeToArea(selectedEmployee.EmployeeId ?? selectedEmployee.Id, areaDetails.Id, selectedPriority)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  name: "AppointmentType",
  data() {
    return {
      assignableOptions: {},
      totalAssignable: 10,
      loading: false,
      page: 1,
      pageSize: 10,
      assignedHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        {
          text: "Priority",
          align: "end",
          sortable: false,
          value: "PriorityStatus",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "Action",
        }
      ],
      assignableHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        {
          text: "Assign to Appointment Type",
          align: "end",
          sortable: false,
          value: "Action",
        }
      ],
      appointmentTypesWithEmployees: [],
      employeesAssigned: [],
      areaDetails: null,
      assignable: [],
      priorityOptions: [],
      showDialog: false,
      selectedEmployee: null,
      selectedPriority: null,
      assignedKey: 0
    };
  },

  watch: {
      assignableOptions() {
        this.getEmployeesGroup();
      }
  },

  methods: {
    async handlePromptPriority(employee) {
        if (this.priorityOptions.length === 0) {
          this.priorityOptions = await this.getPriorities();
          this.priorityOptions.sort((a,b) => b.Order - a.Order);
        }
        this.selectedEmployee = employee;
        this.selectedPriority = null;
        this.showDialog = true;
    },

    async getPriorities() {
      this.loading = true;
      try {
        let { data } = await axios.get(`/priority-statuses`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
          }
        );
        return data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleAssignEmployeeToArea(employeeId, areaId, priorityId) {
      this.loading = true;
      try {
        this.showDialog = false;
        let { data } = await axios.post(`areas/${areaId}/assign-employees`,
          {
            priorityStatusId: priorityId,
            employeeIds: [employeeId]
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
          }
        );
        this.selectedPriority = null;
        this.selectedEmployee = null;
        await this.getAreaEmployeesAssigned(this.areaDetails?.Id);
        await this.getEmployeesGroup();
        return data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async unassignEmployeeArea(employeeId, areaId) {
      this.loading = true;
      try {
        await axios.post(`areas/${areaId}/remove-employees`,
          {
            employeeIds: [
              employeeId
            ]
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
          }
        );
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleRemoveWorker (worker) {
      await this.unassignEmployeeArea(worker.Id, this.areaDetails.Id);
      await this.getAreaEmployeesAssigned(this.areaDetails?.Id);
      await this.getEmployeesGroup();
    },

    async getEmployeesGroup() {
      this.loading = true;
      this.assignable = [];
      let {page, itemsPerPage} = this.assignableOptions;

      itemsPerPage = itemsPerPage > 0 ? itemsPerPage : 1000;

      try {
        let { data } = await axios.get(`employees/acl?page=${page}&size=${itemsPerPage}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.assignable = data.rows.filter((assignable) => !this.employeesAssigned.find((item) => item.Id === assignable.EmployeeId) );
        this.totalAssignable = data.count;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getAreaEmployeesAssigned(id) {
      this.loading = true;
      this.employeesAssigned = [];
      try {
        let { data } = await axios.get(`/areas/${id}/assigned-employees`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        let employees = data.map((item) => {
          item.Name = `${item.FirstName} ${item.LastName}`;
          let priority = this.priorityOptions.find((priority) => priority.Name === item.PriorityStatus);
          item.priorityOrder = priority.Order;
          return item;
        });

        employees.sort((a,b) => b.priorityOrder - a.priorityOrder)

        this.employeesAssigned = employees;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    this.priorityOptions = await this.getPriorities();
    this.areaDetails = this.$route.params;
    if (this.areaDetails?.Name) {
      window.localStorage.setItem('areaDetails', JSON.stringify(this.areaDetails));
    } else {
      this.areaDetails = JSON.parse(window.localStorage.getItem('areaDetails'));
    }
    if (!this.areaDetails?.Name) {
      this.$router.push({ name: 'Appointment Type' });
    }
    await this.getAreaEmployeesAssigned(this.areaDetails?.Id);
    await this.getEmployeesGroup();
  }
};
</script>
<style>
tbody tr :hover {
  cursor: pointer;
}
</style>
